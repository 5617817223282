function UserProcessorModule() {
    var self = this;

    /**
     *
     * @param data
     * @param target
     * @returns {*}
     */
    self.process = function (data, target) {
        if (Array.isArray(data)) {
            if (!target) {
                target = [];
            }
            ko.utils.arrayForEach(data, function (item) {
                target.push(self.addInformation(item));
            });
            return target;
        }
        return self.addInformation(data);
    };


    /**
     * add other information to object
     * @param item
     * @returns {*}
     */
    self.addInformation = function (item) {
        item.display = ko.observable('compact');
        item.observableFans = ko.observable(parseInt(item.fans));
        item.isFoodGuru = ko.computed(function () {
            return item.observableFans() > foodGuruLimit;
        });

        item.fullName = ko.computed(function () {
            return item.name + ' ' + item.surname;
        });

        item.fullNameCatering = ko.computed(function () {
            var text = item.name + ' ' + item.surname;
            if (item.catering)
                text += ' | ' + item.catering.name;
            return text;
        });

        item.link = ko.computed(function () {
            if (item.bundle.seo)
                return item.bundle.seo;
            return window.location.origin + '/user/' + item.id;
        });

        item.getLinkToWebsite = ko.computed(function () {
            if (item.webSite !== '')
                return 'http://' + item.webSite;
            return '#'

        });

        item.getLinkToFacebook = ko.computed(function () {
            if (item.facebookPage !== '')
                return 'https://' + item.facebookPage;
            return '#'
        });

        if (TROVACIGUSTO.favoriteUsers && TROVACIGUSTO.favoriteUsers.indexOf(item.id) >= 0) {
            item.isFavorite = ko.observable(true);
        } else {
            item.isFavorite = ko.observable(false);
        }

        item.addFavoriteUser = function () {

            if (!trovacigusto.userLogged()) {
                trovacigusto.modalLogin.headerLabel("Sign in to follow " + item.fullName());
                trovacigusto.modalLogin.show();
                return;
            }

            if (item.isFavorite() === false) {
                trovacigusto.flagVM.store('user', item.id).then(function (response) {
                    if (response.success) {
                        item.isFavorite(true);
                        item.observableFans(parseInt(item.observableFans()) + 1);
                        pNotify("Added to the people you follow")
                    }
                });
            }
        };

        item.delFavoriteUser = function () {
            if (item.isFavorite() === true) {
                trovacigusto.flagVM.delete('user', item.id).then(function (response) {
                    if (response.success) {
                        item.observableFans(item.observableFans() - 1);
                        item.isFavorite(false);
                        pNotify("You no longer follow this person")
                    }
                });
            }
        };

        item.sendReport = function () {
            var data = {
                type: 'user',
                id: item.id
            };
            rest('POST', '/api/v2/reports/', data).then(function (response) {
                if (!response.success) {
                    if (response.message === 'report.guest')
                        pNotify('You need to Sign in to send a report', 'warning')
                    else
                        pNotify('Problem sending the report. Please try again in a few minutes', 'error')
                } else {
                    pNotify('Report sent successfully.')
                }
            });
        };

        var staffsType = [
            'staff_delivery',
            'staff_cook',
            'staff_waiter',
            'staff_musician',
            'staff_photographer',
            'staff_assistant',
            'staff_barman',
            'staff_publicrelation',
            'staff_sommelier',
            'staff_animator',
            'staff_babysitter',
            'staff_manager',
            'staff_artist'
        ]

        item.staffs = ko.observableArray([])
        staffsType.forEach(function (value, index) {
            if (item[value])
                item.staffs.push(item[value])
        })

        return item

    }

}

