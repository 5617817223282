function TrovaciGustoViewModel () {
  var self = this

  self.type = ko.observable((typeof TROVACIGUSTO !== 'undefined' && TROVACIGUSTO.type) ? TROVACIGUSTO.type : 'user')
  //@TODO rimuovere moduli non necessari in tutto il sito come help
  self.userLogged = ko.observable(null)

  var user_session = JSON.parse(localStorage.getItem('user_logged'))

  self.loginRegisterModule = new LoginRegisterModule(self.type())
  self.modalLogin = new LoginModal(self.loginRegisterModule)
  self.modalRegister = new RegisterModal(self.loginRegisterModule)
  self.modalSearch = new SearchModal()
  self.modalTestimonial = new UserTestimonialModal()
  self.modalOfferFlags = new OfferFlagsModal()
  self.modalOfferShareUser = new OfferShareUserModal()
  self.userProcessor = new UserProcessorModule()
  self.flagProcessor = new FlagProcessorModule(self.userProcessor)
  self.modalHelp = new HelpModal()
  self.systemNotification = new SystemNotificationModule()
  self.modalSystemNotifications = new SystemNotificationsModal(self.systemNotification)

  self.promoFriendsEndDate = function () {
    return PROMO_FRIENDS_END
  }

  self.flagVM = new FlagModule()

  //TODO da spostare in un modulo
  self.requestTrial = function (feature) {
    if (trovacigusto.userLogged() && trovacigusto.userLogged().status === 'enabled') {
      if (typeof feature === 'undefined' || typeof feature !== 'string')
        feature = 'generic'

      var data = {
        userId: trovacigusto.userLogged().id,
        feature: feature
      }
      rest('POST', '/api/v2/subscriptions/trial', data).then(function (response) {
        if (response.success) {
          pNotify('Request sent successfully, we will notify you via email once your free trial period is activated.')
        } else {
          pNotify('There was a problem, please try again. If the issue persists, contact us.', 'error')
        }
      })
    } else {
      pNotify('You are not eligible to request the premium gift.', 'error')
    }
  }

  self.init = function () {

    rest('GET', '/api/v2/auth/').then(function (response) {
      if (response.success && response.data.user) {
        self.userLogged(response.data.user)
        // bisogna impostare l'emiminazione al logout
        //localStorage.setItem("user_logged",  JSON.stringify(response.data.user));
        self.loadNotifications()
      }
    })
  }

  self.loadNotifications = function () {
    if (trovacigusto.userLogged() && trovacigusto.userLogged().status === 'enabled') {
      self.systemNotification.load()
    }
  }

  self.loadNotificationUnread = function () {
    if (trovacigusto.userLogged() && trovacigusto.userLogged().status === 'enabled') {
      self.systemNotification.countUnread()
    }
  }

  window.onload = function () {
    if (typeof later !== 'undefined') {
      var scheduleNotification = later.parse.cron('*/2 * * * *')
      later.setInterval(self.loadNotificationUnread, scheduleNotification)
    }
  }

  self.init()

  return self
}

if (document.getElementById('trovacigusto-bind')) {
  var trovacigusto = new TrovaciGustoViewModel()
  ko.applyBindings(trovacigusto, document.getElementById('trovacigusto-bind'))
}

