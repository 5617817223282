function AppleModule () {
  var self = this

  self.user = ko.observable(null)
  self.loading = ko.observable(false)
  self.image = ko.observable(null)
  self.registerType = ko.observable('user')
  self.invitationCode = ko.observable('')
  self.redirectCallback = function (redirectUrl) {
    location.href = redirectUrl
  }

  self.init = function () {
    AppleID.auth.init({
      clientId: 'com.trovacigusto.signin',
      scope: 'name email',
      redirectURI: window.location.origin,
      state: 'Initial user authentication request',
      usePopup: true

    })

    document.addEventListener('AppleIDSignInOnSuccess', function (data) {
      console.log("AppleIDSignInOnSuccess", data);
    })
    document.addEventListener('AppleIDSignInOnFailure', function (error) {
      console.log("AppleIDSignInOnFailure", error)
    })
  }

  self.bindUser = function (appleUser, onSuccess) {
    self.loading(true)
    return rest('POST', '/api/v2/apple/signin', appleUser)
      .then(function (response) {
        if (response.success) {
          self.user(response.data)
          trovacigusto.loginRegisterModule.onesignalGetData()

          pNotify('Your Apple account has been successfully linked')
          onSuccess(response)
        } else {
          pNotify('Your Apple account could not be linked.', 'warning')
        }
        self.loading(false)
      })
      .catch(function () {
        self.loading(false)
        pNotify('An error occurred. Please try reloading the page and/or try again later.', 'error')
      })
  }

  self.signIn = function (response) {
    self.loading(true)
    //debugLog(response)
    var firstName
    var lastName
    var idToken
    if (response.idToken) {
      // native-only
      idToken = response.idToken
      firstName = response.firstName
      lastName = response.lastName
    } else {
      // browser-only
      if (response.authorization) {
        idToken = response.authorization.id_token
      }

      // Only shows once Apple only returns
      // the user object the first time
      // the user authorizes the app.
      // Persist this information from your app;
      // subsequent authorization requests won’t contain the
      // user object.
      if (response.user && response.user.name) {
        firstName = response.user.name.firstName
        lastName = response.user.name.lastName
      }
    }

    if (idToken) {
      const { payloadObj } = KJUR.jws.JWS.parse(idToken)
      if (payloadObj) {
        const { email, sub } = payloadObj

        const payload = {
          email: email,
          first_name: firstName,
          last_name: lastName,
          provider_type: 'apple',
          provider_token: idToken,
          provider_uid: sub,
          type: self.registerType(),
          invitationCode: self.invitationCode()
        }
        self.bindUser(payload, function (response) {
          self.redirectCallback(response.data.redirect ? response.data.redirect : '/');
        })
      }
    } else {
        pNotify('An error occurred. You did not properly authorize your Apple account', 'warning')
      }

    }

    self.unbindUser = function () {
      self.loading(true)
      rest('GET', '/api/v2/apple/unbind')
        .then(function (response) {
          if (response.status === 'user.reset.password') {
            self.user(null)
            pNotify('Your Apple account has been successfully unlinked. You need to set a password to access your account', 'warning')
          }
          self.loading(false)
        })
        .catch(function () {
          self.loading(false)
          pNotify('An error occurred. Please try reloading the page and/or try again later', 'error')
      })
  }

  self.init()

  return self
}