function SearchCateringsModule () {
  var self = this

  self.query = ko.observable('')
  self.errorMessage = ko.observable('')
  self.searched = ko.observable(false)
  self.pagination = new PaginationModule()
  self.filters = ko.observable(null)

  self.labelSearch = ko.computed(function () {
    if (self.filters && self.filters() === 'suppliers')
      return 'Search for a food supplier you love'
    else
      return 'Search for a specific food business'
  })

  self.addFavoriteCatering = function (item) {
    if (!trovacigusto.userLogged()) {
      trovacigusto.modalLogin.headerLabel('Already a user?<br></br> Sign in to become a Fan')
      trovacigusto.modalLogin.show()
      return
    }
    Swal.fire({
      title: '<i class="fa fa-heart-o text-contrast" style="margin-top:2px; margin-right:5px;"></i> Do you want to become a fan?',
      html: '<p class="text-left">Congratulations, you are about to enrich your food network with this Business:</p><br>' +
        '<ul class="text-left">\n' +
        '    <li>You will find its Offers always updated on your Diary and receive the best news via email or notification</li>\n' +
        '    <li>You will have access to its exclusive "Fan Only" Offers and Deals</li>\n' +
        '</ul>\n',
      showCancelButton: true,
      cancelButtonColor: '#CC1100',
      confirmButtonText: 'Yes, confirm!',
      cancelButtonText: 'No, cancel',
      customClass: {
        confirmButton: 'btn btn-success mg-r-xs',
        cancelButton: 'btn btn-gray',
      },
      buttonsStyling: false
    }).then(function (result) {
        if (result.value) {
          trovacigusto.flagVM.store('catering', item.id)
        }
      }
    )
  }

  self.search = function () {
    var query = self.query()
    self.pagination.processor(new CateringProcessorModule())
    self.pagination.reset()
    self.searched(false)
    self.errorMessage('')
    if (self.filters())
      query += '&filters=' + self.filters()

    self.pagination.url('/api/v2/caterings/search?q=' + query)
    self.pagination.load().then(function (response) {
      if (response.success) {
        self.resetSearchQuery()
        self.searched(true)
      } else {
        self.errorMessage(response.message)
      }
    })
  }

  self.showMessage = ko.computed(function () {
    if (self.pagination.data().length <= 0 && self.searched()) {
      return '<p class="text-bold no-mg">The search returned zero results.</p><br>' +
        ' <p>Can\'t find the Business you\'re looking for? <br>Allow them to activate\n' +
        '                <a class="text-contrast" href="/user/favorite/caterings#locali">\n' +
        '                    using your invitation code\n' +
        '                </a> (required):\n' +
        '                with the FRIENDS Promo you will receive a 25£ credit!<sup>*</sup><br><br>\n' +
        '                <span class="text-sm">*The Credit can be used at the invited Business on a minimum purchase of 50£ and within 90 days\n' +
        '                    from the date of acceptance of your invitation.</span>\n' +
        '            </p>'
    }

    if (self.errorMessage !== '') {
      return self.errorMessage()
    }
  })

  self.closeSearch = function () {
    self.pagination.reset()
    self.searched(false)
    self.resetSearchQuery()
  }

  self.resetSearchQuery = function () {
    self.query('')
    self.errorMessage('')
  }

  self.registerInvite = function (item) {
    trovacigusto.modalRegister.headerLabel('To become a fan Create your free account')
    trovacigusto.modalRegister.loginRegisterModule.modeInvite(true)
    trovacigusto.modalRegister.loginRegisterModule.catering(item)
    trovacigusto.modalRegister.loginRegisterModule.invitationCode(item.referral_code)
    trovacigusto.modalRegister.loginRegisterModule.senderInviteType('catering')
    trovacigusto.modalRegister.show()
  }

  return self

}