function LoginRegisterModule (registerType, invitationCode) {
  var self = this
  self.registerType = ko.observable('user')
  self.invitationCode = ko.observable('')
  self.facebookModule = new FacebookModule()
  self.googleModule = new GoogleModule()
  self.appleModule = new AppleModule()

  self.redirectUrl = ko.observable((typeof TROVACIGUSTO !== 'undefined') ? TROVACIGUSTO.redirectUrl : null)

  self.catering = ko.observable(null)
  self.user = ko.observable(null)
  self.password = ko.observable(null)
  self.password_confirmation = ko.observable(null)
  self.loading = ko.observable(false)
  self.responseMessageSuccess = ko.observable('')
  self.responseMessageError = ko.observable('')
  self.passwordMessageError = ko.observable('')
  self.acceptedPrivacy = ko.observable(false)
  self.email = ko.observable(null)

  self.showPassword = ko.observable(false)
  self.senderInviteType = ko.observable('catering')
  self.modeInvite = ko.observable(false)
  self.inputRegisterLabel = ko.observable('Enter your email')


  self.email.extend({required: true, email: true})
  self.password.extend({required: true})
  self.password_confirmation.extend({required: true})

  self.register = function () {
    var message = ''
    self.basicRegister()
        .then(function (response) {
          if (response.success && response.data.redirect)
            window.location.href = response.data.redirect;
          if (response.success) {
            self.onesignalGetData()
            if (self.registerType() !== 'catering' && self.registerType() !== 'supplier') {
              message = 'Ok! Now complete and submit the request, then follow the instructions after submission'
              self.responseMessageSuccess(message)
              pNotify(message)
            }
          }
        })
        .catch(function (response) {
          self.loading(false)
        })
  }

  self.login = function () {
    self.basicLogin()
        .then(function (response) {
          if (response.success) {
            pNotify('Login successful')
            self.onesignalGetData()
            if (response.data.url)
              location.href = response.data.url
            else
              location.reload()
          } else {
            self.responseMessageError('Failed login. ' + response.message)
            pNotify('Failed login. ' + response.message, 'error')
          }
        })
  }

  self.basicRegister = function () {
    var message = ''
    var data = {
      email: self.email(),
      type: self.registerType(),
      invitationCode: self.invitationCode(),
      senderType: self.senderInviteType(),
      cateringId: self.catering() ? ko.unwrap(self.catering().id) : '',
      userId: self.user() ? ko.unwrap(self.user().id) : ''
    }

    if (!self.email()) {
      self.responseMessageError('Enter an email address')
      pNotify('Enter an email address', 'warning')
      return Promise.reject()
    }
    if (!self.checkPolicy())
      return Promise.reject()
    self.responseMessageSuccess('')
    self.responseMessageError('')

    var url = '/api/v2/register'
    if (self.modeInvite())
      url = '/api/v2/invite/register'

    self.loading(true)
    return rest('POST', url, data)
        .then(function (response) {
          if (response.success) {
            self.onesignalGetData()
          } else {
            message = 'Registration failed, email incorrect or already in use. If you have forgotten your password, go to the <a class=\'text-danger\' href=\'/login\'>access screen</a> and follow the appropriate procedure to recover it.'
            trovacigusto.modalLogin.loginRegisterModule.responseMessageError(message)
            pNotify(message, 'error', null, null, false)
          }
          self.modeInvite = ko.observable(false)
          self.loading(false)
          return response
        })
  }

  self.basicLogin = function () {
    if (!(self.password() && self.email())) {
      self.responseMessageError('Enter your email address and password')
      pNotify('Enter your email address and password', 'warning')
      return Promise.reject()
    }
    self.responseMessageSuccess('')
    self.responseMessageError('')
    var data = {
      password: self.password(),
      email: self.email(),
      redirectUrl: self.redirectUrl()
    }
    self.loading(true)
    return rest('POST', '/api/v2/login', data)
        .then(function (response) {
          if (response.success && self.modeInvite()) {
            trovacigusto.flagVM.store('catering', self.catering() ? ko.unwrap(self.catering().id) : '')
          }
          self.loading(false)
          return response
        })
        .fail(function (response) {
          self.loading(false)
          return response
        })

  }


  self.onesignalGetData = function () {
    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') >= 0) {
      gonative.onesignal.onesignalInfo().then(function (oneSignalInfo) {
        rest('POST', '/api/v2/users/onesignal', oneSignalInfo)
      })
    }
  }

  self.getRedirectCallback = function (forceRedirectUrl) {
    return function (redirectUrl) {
      if (redirectUrl) {
        var url = new URL(forceRedirectUrl && typeof forceRedirectUrl === 'string' ? forceRedirectUrl : redirectUrl, getBaseURL())
        url.searchParams.set('logged', Math.random().toString(36))
        location.href = url.href
      }
    };
  }

  self.facebookSignin = function (forceRedirectUrl) {
    self.facebookModule.registerType(self.registerType())
    self.facebookModule.invitationCode(self.invitationCode())
    self.facebookModule.redirectCallback = self.getRedirectCallback(forceRedirectUrl)
    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') >= 0) {
      //alert('Accesso con Facebook')
      window.gonative.socialLogin.facebook.login({
        'callback': function (response) {
          self.facebookModule.facebookLoginCallback(response)
        },
        'scope': 'public_profile, email'
      })
    } else {
      self.facebookModule.checkStatus()
    }
  }

  self.facebookSignup = function (forceRedirectUrl) {
    if (self.checkPolicy()) {
      self.facebookSignin(forceRedirectUrl)
    }
  }

  self.googleSignin = function (forceRedirectUrl) {
    self.googleModule.registerType(self.registerType())
    self.googleModule.invitationCode(self.invitationCode())
    self.googleModule.redirectCallback = self.getRedirectCallback(forceRedirectUrl)

    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') >= 0) {
      //alert('Accesso con Google')
      window.gonative.socialLogin.google.login({
        'callback': function (response) {
          self.googleModule.googleLoginCallback(response)
        }
      })
    } else {
      self.googleModule.login()
    }
  }

  self.googleSignup = function (forceRedirectUrl) {
    if (self.checkPolicy()) {
      self.googleSignin(forceRedirectUrl)
    }
  }

  self.appleSignin = function (forceRedirectUrl) {
    self.appleModule.registerType(self.registerType())
    self.appleModule.invitationCode(self.invitationCode())
    self.appleModule.redirectCallback = self.getRedirectCallback(forceRedirectUrl);
    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') >= 0) {
      //alert('Accesso con Apple')
      window.gonative.socialLogin.apple.login({
        'callback': function (response) {
          self.appleModule.signIn(response)
        },
        'scope': 'full_name, email'
      })

    } else {
      AppleID.auth.signIn()
          .then(function (response) {
            self.appleModule.signIn(response)
          })
          .catch(function (error) {
          })
    }
  }

  self.appleSignup = function (forceRedirectUrl) {
    if (self.checkPolicy()) {
      self.appleSignin(forceRedirectUrl)
    }
  }

  self.checkPolicy = function () {
    if (!self.acceptedPrivacy()) {
      self.responseMessageError('You must accept the Privacy Policy and Terms & Conditions')
      pNotify('You must accept the Privacy Policy and Terms & Conditions', 'warning')
      return false
    }
    return true
  }

  const togglePassword = document.querySelector('#togglePassword');
  const toggleConfirmPassword = document.querySelector('#toggleConfirmPassword');
  const password = document.querySelector('#password');
  const confirmPassword = document.querySelector('#confirmPassword');

  if (togglePassword && password) {
    togglePassword.addEventListener('click', function (e) {
      // toggle the type attribute
      const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
      password.setAttribute('type', type);
      // toggle the eye slash icon
      this.classList.toggle('fa-eye-slash');
    });
  }

  if (toggleConfirmPassword && confirmPassword) {
    toggleConfirmPassword.addEventListener('click', function (e) {
      // toggle the type attribute
      const type = confirmPassword.getAttribute('type') === 'password' ? 'text' : 'password';
      confirmPassword.setAttribute('type', type);
      // toggle the eye slash icon
      this.classList.toggle('fa-eye-slash');
    });
  }

  self.checkConfirmPassword = function () {
    self.passwordMessageError('')
    if (!self.password() || !self.password_confirmation())
      return false
    if (self.password() !== self.password_confirmation()) {
      self.passwordMessageError('The passwords do not match')
      return false
    }
    return true
  }

  self.checkPasswordRules = function () {
    self.passwordMessageError('')
    if (self.password() && self.password().length < 6) {
      self.passwordMessageError('The password must be at least 6 characters long')
      return false
    }
    if (self.password() && !self.password().match(/[a-z]/)) {
      self.passwordMessageError('The password must contain at least one lowercase letter')
      return false
    }
    if (self.password() && !self.password().match(/[A-Z]/)) {
      self.passwordMessageError('The password must contain at least one uppercase letter')
      return false
    }
    if (self.password() && !self.password().match(/[0-9]/)) {
      self.passwordMessageError('The password must contain at least one number')
      return false
    }
    return self.checkConfirmPassword() !== false;

  }

  self.setInvite = function (registerType, invitationCode) {
    if (registerType) {
      self.registerType(registerType)
      self.inputRegisterLabel(self.registerType() === 'catering' ? 'Enter your business email' : 'Enter your email')

    }
    if (invitationCode)
      self.invitationCode(invitationCode)
  }

  self.setInvite(registerType, invitationCode)

  self.checkisAppDevice = function () {
    //JavaScript on your website:
    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') > -1) {
      //hide/show elements within the app
      $('.mobileNav').hide()
      $('.appOnly').show()
    }
    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') > -1) {
      $('.appAndroidOnly').show()
      $('.appIosOnly').hide()
    }
    if (navigator.userAgent.toLowerCase().indexOf('apicodeversion') > -1) {
      $('.appIosOnly').show()
      $('.appAndroidOnly').hide()

    }
  }

  self.checkisAppDevice()

  return self
}

