function SystemNotificationsModal(systemNotification) {

    var self = new ModalModule();

    self.catering = ko.observable();
    self.user = ko.observable();
    self.details = ko.observable(false);
    self.systemNotification = systemNotification;
    self.modalSize('modal-md');

    self.headerLabel('<i class="fa fa-bell"></i> Your notifications');
    self.bodyTemplate('systemNotificationsModalTemplate');
    self.headerTemplate('headerSystemNotificationsTemplate');

    self.loading = ko.computed(function () {
        return self.systemNotification.loading();
    });

    self.show = function () {
        closeAllModal();
        self.modalVisible(true);
        self.systemNotification.refresh(10);
    };

    return self;
}