function RegisterModal(loginRegisterModule) {

    var self = new ModalModule();

    self.catering = ko.observable();
    self.user = ko.observable();
    self.templateIntro = ko.observable('register-intro');

    self.headerLabel('New User? Find us on FindGusto!');
    self.bodyTemplate('registerModalTemplate');
    self.headerTemplate('headerRegisterModalTemplate');
    self.modalSize = ko.observable('modal-sm');
    self.showOtherButton = ko.observable(false);
    self.showAppBadge = ko.observable(navigator.userAgent.indexOf("apicodeversion") === -1 && /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent));
    self.loginRegisterModule = loginRegisterModule ? loginRegisterModule : new LoginRegisterModule();

    self.show = function () {
        closeAllModal()
        self.loginRegisterModule.acceptedPrivacy(false)
        self.modalVisible(true)

    }

    return self;
}