function PromoFriendsModal() {

    var self = new ModalModule();

    self.bodyTemplate('promoFriendsModalTemplate');
    self.headerTemplate('headerPromoFriendsModalTemplate');
    self.modalSize('modal-sm');

    self.isForSupplier = ko.observable(false);
    self.isForFoodGuru = ko.observable(false);
    self.isForFan = ko.observable(false);
    self.isForCatering = ko.observable(false);
    self.catering = null;

    self.reset = function () {
        self.isForSupplier(false);
        self.isForFoodGuru(false);
        self.isForFan(false);
        self.isForCatering(false);
    };

    self.show = function () {
        if (self.isForSupplier()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invite your suppliers to register:<br/>25&pound; For you with the FRIENDS Promo!');
        } else if (self.isForFoodGuru()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invite your friends to follow you:<br/>the privileges of Food Guru are just a click away!')
        } else if (self.isForFan()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invite all your clients to follow you:<br/> you\'ll gain more visibility and success!')
        } else if (self.isForCatering()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invite the business you love to sign up:<br/>25&pound; for you with the FRIENDS Promo!');
        }

        self.modalVisible(true);
    };

    self.showPromoFriendsCatering = function () {
        self.reset();
        self.isForCatering(true);
        self.show();
    };
    self.showPromoFriendsFoodGuru = function () {
        self.reset();
        self.isForFoodGuru(true);
        self.show();
    };
    self.showPromoFriendsSupplier = function () {
        self.reset();
        self.isForSupplier(true);
        self.show();
    };
    self.showPromoFriendsFan = function () {
        self.reset();
        self.isForFan(true);
        self.show();
    };

    self.inviteSuppliers = function () {
        if (self.catering && location.href !== '/catering/' + ko.unwrap(self.catering.id) + '/admin/supplier-invite') {
            location.href = '/catering/' + ko.unwrap(self.catering.id) + '/admin/supplier-invite';
        }
        self.close()
    };

    self.cateringInviteUsers = function () {
        if (self.catering && location.href !== '/catering/' + ko.unwrap(self.catering.id) + '/admin/supplier-invite') {
            location.href = '/catering/' + ko.unwrap(self.catering.id) + '/admin/invite';
        }
        self.close()

    };

    self.userInviteUsers = function () {
        location.href = '/user/favorite/users#invite';
    };

    self.userInviteCaterings = function () {
        location.href = '/user/favorite/caterings#locali';
    };

    return self;
}