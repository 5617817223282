function UserTestimonialModal () {

  var self = new ModalModule()

  self.catering = ko.observable()
  self.user = ko.observable()
  self.testimonial = ko.observable(null)

  self.headerLabel('<i class="fa fa-heart-o text-contrast"></i> Write the "Declaration" (of love!) and boost your popularity!\n')
  self.bodyTemplate('testimonialModalTemplate')
  self.headerTemplate('headerTestimonialModalTemplate')

  self.loadTestimonial = function () {
    return rest('GET', '/api/v2/testimonials/' + self.catering().id)
      .then(function (response) {
        if (response.success) {
          self.testimonial(ko.mapping.fromJS(response.data.testimonial))
        } else {
          pNotify('Problem loading Declaration of Love', 'error')
        }
        return response
      })
  }

  self.checkSaveTestimonial = function () {
    if (!self.testimonial().is_visible())
      self.saveTestimonial()
    else {
      Swal.fire({
        title: 'Are you sure you want to save it?',
        text: 'Your declaration (of love!) has been approved by ' + self.catering().name + '. By saving the change, your Declaration will need to be made visible again by the Business',
        showCancelButton: true,
        cancelButtonColor: '#CC1100',
        confirmButtonText: 'Yes, save',
        cancelButtonText: 'No, keep',
        customClass: {
          confirmButton: 'btn btn-success mg-r-xs',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false
      }).then(function (result) {
        if (result.value) {
          self.saveTestimonial()
        }
      })
    }
  }

  self.saveTestimonial = function () {
    var data = {
      testimonial: self.testimonial(),
    }
    if (self.testimonial().message() && self.testimonial().message().length > 3) {
      rest('PUT', '/api/v2/testimonials/' + self.testimonial().catering_id(), data)
        .then(function (response) {
          if (response.success) {
            self.testimonial(ko.mapping.fromJS(response.data.testimonial))
            pNotify('Declaration (of love!) Saved')
          } else {
            pNotify('Declaration (of love!) Not saved.', 'error')
          }
        })
    } else {
      pNotify('The declaration of love cannot be empty or less than 4 characters.', 'error')
    }
  }

  self.checkDeleteTestimonial = function () {
    if (!self.testimonial().is_visible())
      self.deleteTestimonial()
    else {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        text: 'Your "Declaration of Love" has been approved by ' + self.catering().name + '. By deleting it, it will be hidden from the Business\'s Page.',
        showCancelButton: true,
        cancelButtonColor: '#CC1100',
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, keep',
        customClass: {
          confirmButton: 'btn btn-success mg-r-xs',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false
      }).then(function (result) {
        if (result.value) {
          self.deleteTestimonial()
        }
      })
    }
  }

  self.deleteTestimonial = function () {
    rest('DELETE', '/api/v2/testimonials/' + self.testimonial().id())
      .then(function (response) {
        if (response.success) {
          pNotify('Declaration (of love) deleted')
          self.close()
        } else {
          pNotify('Declaration (of love) not deleted', 'error')
        }
      })
      .catch(function (res) {
        pNotify('Declaration (of love) not deleted.', 'error')
      })
  }

  self.load = function (catering) {
    if (catering)
      self.catering(catering)
    self.loadTestimonial().then(function () {
      self.init()

    })
  }

  self.getShareUrl = function () {
    var url = ''
    if (self.catering().bundle.seo)
      url = self.catering().bundle.seo
    else
      url = window.location.origin + '/catering/' + self.catering().id
    if (self.testimonial().user_id())
      url += '?testimonial=' + self.testimonial().user_id() + '%23tab-fan'
    return url
  }

  self.getShareText = function () {
    return 'That\'s why I love ' + self.catering().name
  }

  self.shareFacebook = function () {

    window.open('https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + self.getShareUrl())
  }

  self.shareTwitter = function () {
    window.open('https://twitter.com/share?url=' + self.getShareUrl() + '&text=' + self.getShareText())
  }

  self.shareSms = function () {
    window.open('sms:?body=' + self.getShareText() + ': ' + self.getShareUrl())
  }

  self.shareWhatsapp = function () {
    window.open('https://api.whatsapp.com/send?text=' + self.getShareText() + ': ' + self.getShareUrl())
  }

  return self
}