function GoogleModule () {
  var self = this

  self.user = ko.observable(null)
  self.loading = ko.observable(false)
  self.image = ko.observable(null)
  self.registerType = ko.observable('user')
  self.invitationCode = ko.observable('')

  self.redirectCallback = function (redirectUrl) {
    location.href = redirectUrl
  }

  self.login = function () {
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        google.accounts.id.prompt()
      }
    });
  }

  self.logout = function () {
    self.loading(true)
    rest('GET', '/api/v2/google/unbind')
      .then(function (response) {
        if (response.status === 'user.reset.password') {
          self.user(null)
          pNotify('Your Google account has been successfully disconnected. If you haven\'t already,  <a href="/user/settings">set a password</a> to be able to log in', 'warning')
        }
        if (typeof google !== 'undefined')
          google.accounts.id.revoke(response.data, done => {
            console.log('consent revoked')
          })
        self.loading(false)
      })
      .catch(function () {
        self.loading(false)
        pNotify('An error occurred. Try reloading the page and/or try again later', 'error')
      })
  }

  self.bindUser = function (payload, onSuccess) {
    self.loading(true)
    return rest('POST', '/api/v2/google/register', payload)
      .then(function (response) {
        if (response.success) {
          pNotify('Your Google account has been successfully connected')
          self.user(response.data.user)
          trovacigusto.loginRegisterModule.onesignalGetData()
          onSuccess(response)
        } else {
          pNotify('Your Google account was not connected.', 'warning')
        }
        self.loading(false)
      })
      .catch(function () {
        self.loading(false)
        pNotify('An error occurred. Try reloading the page and/or try again later', 'error')
      })
  }

  self.googleLoginCallback = function (response) {
    console.log('Google Login Callback')

    let idToken
    if (response.credential) {
      // browser-only
      idToken = response.credential
    } else {
      // native-only
      idToken = response.idToken
    }

    if (idToken) {
      const { payloadObj } = KJUR.jws.JWS.parse(idToken)
      if (payloadObj) {
        const { given_name, family_name, email, sub } = payloadObj

        const payload = {
          email,
          first_name: given_name,
          last_name: family_name,
          provider_type: 'google',
          provider_token: idToken,
          provider_uid: sub,
          type: self.registerType(),
          invitationCode: self.invitationCode(),
          redirect: window.location.href
        }
        //debugLog(payload)

        self.bindUser(payload, function (response) {
          self.redirectCallback(response.data.redirect ? response.data.redirect : '/');
        })
      }
    } else {
      pNotify('An error occurred. You have not correctly authorized your Google account', 'warning')
    }
  }

  self.load = function () {
    if (typeof google === 'undefined' || typeof google.accounts === 'undefined') {
      return
    }
    google.accounts.id.initialize({
      client_id: '301120554578-5ctenkonmp7266gmkbqoarbd5scunl2c.apps.googleusercontent.com',
      callback: self.googleLoginCallback
    })// also display the One Tap dialog

  }
  window.addEventListener("load", function () {
    self.load()
  });

  return self
}
