function LoginModal(loginRegisterModule) {

    var self = new ModalModule();

    self.catering = ko.observable();
    self.user = ko.observable();

    self.headerLabel('Already a member? Access:');
    self.bodyTemplate('loginModalTemplate');
    self.headerTemplate('headerLoginModalTemplate');
    self.modalSize = ko.observable('modal-sm');
    self.loginRegisterModule = loginRegisterModule ? loginRegisterModule : new LoginRegisterModule();

    self.show = function () {
        self.modalVisible(true)

        const togglePasswordModal = document.querySelector('#togglePasswordModal');
        const passwordModal = document.querySelector('#passwordModal');
        if (togglePasswordModal && passwordModal) {
            togglePasswordModal.addEventListener('click', function (e) {
                // toggle the type attribute
                const type = passwordModal.getAttribute('type') === 'password' ? 'text' : 'password';
                passwordModal.setAttribute('type', type);
                // toggle the eye slash icon
                this.classList.toggle('fa-eye-slash');
            });
        }
    }

    return self;
}