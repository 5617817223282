function FacebookModule () {
  var self = this

  self.user = ko.observable(null)
  self.loading = ko.observable(false)
  self.image = ko.observable(null)
  self.registerType = ko.observable(null)
  self.invitationCode = ko.observable(null)

  self.redirectCallback = function (redirectUrl) {
    location.href = redirectUrl
  }

  self.login = function () {
    FB.login(function (response) {
      self.facebookLoginCallback(response)
    }, { scope: 'public_profile, email' })
  }

  self.facebookLoginCallback = function (response) {
    var accessToken
    var userId

    if (response.status === 'connected') {
      accessToken = response.authResponse.accessToken
      userId = response.authResponse.userID
    } else if (response.type === 'facebook') {
      accessToken = response.accessToken
      userId = response.userId
    }
    if (accessToken) {
      self.setUser(accessToken, userId, function (response) {
        self.redirectCallback(response.data.url ? response.data.url : '/');
      })
    } else {
      debugLog('facebook missing access token')
      pNotify('You have not completely authorized Facebook or try to delete the cache and try again', 'warning')
      console.log('User cancelled login or did not fully authorize.')
    }
  }

  self.setUser = function (accessToken, userId, onSuccess) {
    var url = '/me?fields=email,id,first_name,last_name,picture'
    if (userId)
      url = '/' + userId + '?fields=email,id,first_name,last_name,picture'

    FB.api(url, 'GET', {
      access_token: accessToken,
    }, function (response) {

      if (response.error) {
        throw new Error(response);
      }

      var payload = {
        id: response.id,
        email: response.email,
        first_name: response.first_name,
        last_name: response.last_name,
        provider_type: 'facebook',
        accessToken: accessToken,
        type: self.registerType(),
        invitationCode: self.invitationCode()

      }

      rest('POST', '/api/v2/facebook/bind', payload)
        .then(function (response) {
          if (response.success) {
            self.user(response.data)
            pNotify('Successful access, wait ...', 'success')
            trovacigusto.loginRegisterModule.onesignalGetData()
          } else {
            pNotify('Your Facebook account has not been linked.', 'warning')
          }
          self.loading(false)
          onSuccess(response)
        })
        .catch(function (res) {
          debugLog(res)
          pNotify('Login failed, please try another method.', 'warning')
        })
    })
  }

  self.logout = function () {
    FB.logout(function (response) {
      pNotify('Log out of Facebook and try logging in again', 'warning')
      console.info('User canceled login or did not fully authorize.')
    })
  }

  self.checkStatus = function () {
    FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        if (response.authResponse) {
          self.setUser(response.authResponse.accessToken, response.authResponse.userID, function (response) {
            self.redirectCallback(response.data.url ? response.data.url : '/');
          })
        }
      } else {
        self.login()
      }
    })
  }

  self.unbindUser = function () {
    self.loading(true)
    rest('GET', '/api/v2/facebook/unbind')
      .then(function (response) {
        if (response.status === 'user.reset.password') {
          self.user(null)
          pNotify('Your Facebook account has been successfully disconnected. If you haven’t created a password yet, you need to set one up in order to access FindGusto without Facebook.', 'warning')
        }
        self.loading(false)
      })
      .catch(function () {
        self.loading(false)
        pNotify('An error has occurred. Try reloading the page and/or try again later.', 'error')
      })
    self.logout()
  }

  self.bindUser = function () {
    FB.getLoginStatus(function (response) {
      self.facebookLoginCallback(response, function (response) {
        if (response)
          window.location.href = response
      })
    })

  }

  self.bindPicture = function () {
    FB.login(function (response) {
      if (response.authResponse) {
        FB.api('/me/picture?height=500&width=500&redirect=false', function (response) {
          if (!response.error) {
            self.loading(true)
            rest('POST', '/api/v2/facebook/image', { data: response.data })
              .then(function (response) {
                if (response.success) {
                  self.image({ uri: response.data.image.uri })
                } else {
                  pNotify('An error occurred while connecting to Facebook. Try disconnecting and logging in again with Facebook', 'warning')
                }
                self.loading(false)
              })
          } else {
            pNotify('An error occurred while connecting to Facebook. Try disconnecting and logging in again with Facebook', 'warning')
          }
        })
      }
    })
  }

  return self
}